import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getValidIntCost, isUndefinedNullOrEmpty, getValid$Cost, getProductPrice, getPromoPrice, replaceServiceName, getWirelessServRef } from '../common/common';
import { getConfigs } from '../config';
import { setSelectedBundle } from '../store/action/OrderAction';
import { TRANSLATE } from '../helpers/OrderHelperFunctions';


const config = getConfigs();

export const useSessionData = () => {
  const { sessionResponse, orderInfo, TFN, extendedInfo, referrer, customTFN, customWhiTFN, setActiveProductTab } = useSelector(
    (state) => ({
      sessionResponse: state.OrderState.sessionResponse,
      TFN: state.OrderState.TFN,
      orderInfo: state.OrderState.orderInfo,
      extendedInfo: state.OrderState.extendedInfo,
      referrer: state.OrderState.referrer,
      customTFN: state.OrderState.customTFN,
      customWhiTFN: state.OrderState.customWhiTFN,
      setActiveProductTab: state.OrderState.setActiveProductTab
    }),
  );

  return {
    sessionResponse,
    orderInfo,
    TFN,
    extendedInfo,
    referrer,
    customTFN,
    customWhiTFN,
    setActiveProductTab
  };
};

export const useServiceablitityData = () => {
  const { serviceabilityCheck, normalizedAddress, adslShippingCost, bundlesDetails, scheduleAppt, callLogId, canScheduleAppt,mrcPosProducts,scheduleLoading,isRtsVisible,rtsAttemptCount } = useSelector(
    (state) => ({
      serviceabilityCheck: state.OrderState.serviceabilityCheck,
      normalizedAddress: state.OrderState.normalizedAddress,
      adslShippingCost: state.OrderState.adslShippingCost,
      bundlesDetails: state.OrderState.bundlesDetails,
      scheduleAppt: state.OrderState.scheduleAppt,
      callLogId: state.OrderState.callLogId,
      canScheduleAppt: state.OrderState.canScheduleAppt,
      mrcPosProducts:state.OrderState.mrcPosProducts,
      scheduleLoading: state.OrderState.scheduleLoading,
      isRtsVisible: state.OrderState.isRtsVisible,
      rtsAttemptCount: state?.OrderState?.rtsAttemptCount
    }),
  );

  return {
    serviceabilityCheck,
    normalizedAddress,
    adslShippingCost,
    bundlesDetails,
    scheduleAppt,
    callLogId,
    canScheduleAppt,
    mrcPosProducts,
    scheduleLoading,
    isRtsVisible,
    rtsAttemptCount
  };
}

export const useCartData = () => {
  const { internetPlan, wirelessCartInfo = [], vasPlanInfo, serviceTerm, adslShippingCost, isProductsAdded, errorMessage, isVasOnlyFlow, isMobileContainer, languagePref, selectedBundleData, mobileProvider, generalTerms, selectedProduct, broadbandEmailError  } = useSelector(
    (state) => ({
      isProductsAdded: state.OrderState.isProductsAdded,
      internetPlan: state.OrderState.internetPlan,
      bundlePlan: state.OrderState.bundlePlan,
      wirelessCartInfo: state.OrderState.wirelessCartInfo,
      vasPlanInfo: state.OrderState.vasPlanInfo,
      serviceTerm: state.OrderState.serviceabilityCheck.serviceTerm,
      adslShippingCost: state.OrderState.adslShippingCost,
      errorMessage: state.OrderState.errorMessage,
      isVasOnlyFlow: state.OrderState.isVasOnlyFlow,
      isMobileContainer: state.OrderState.isMobileContainer,
      languagePref: state.OrderState.languagePref,
      selectedBundleData: state.OrderState.selectedBundleData,
      mobileProvider: state.OrderState.mobileProvider,
      generalTerms : state.OrderState.generalTerms,
      selectedProduct: state.OrderState.selectedProduct,
      broadbandEmailError: state.OrderState.broadbandEmailError
    }),
  );

  const { serviceabilityCheck,mrcPosProducts } = useServiceablitityData();
  const { bundleData, plans, bundles: prequalBundleResp } = serviceabilityCheck;
  const dispatch = useDispatch();

  const isBundleVisible = config.SHOW_BUNDLE === 'true' && prequalBundleResp?.length > 0 && plans?.length > 0;

  let { price = 0, equipmentCost = 0, installationCost = 0, techDetail, servRef, setupCost } = internetPlan;
  // check for internet plan added
  const isInternetAdded = Object.keys(internetPlan).length > 0;
  // check for mobile plan added
  const { wirelessPlanInfo = [], wirelesServeRef = '' } = wirelessCartInfo;

  const isMobileAdded = wirelessPlanInfo.length > 0;
  // calculate cart count
  const getWirelessProdCount = wirelessCartInfo?.wirelessPlanInfo?.length ? (wirelessCartInfo.wirelessPlanInfo).reduce((a, { quantity }) => a + quantity, 0) : 0;
  let productCount = Object.keys(internetPlan).length > 0 ? 1 + getWirelessProdCount : getWirelessProdCount;

  // check vas products added
  const vasOrdered = !isUndefinedNullOrEmpty(vasPlanInfo) && Object.keys(vasPlanInfo).length;
  const vasPlan = vasOrdered ? vasPlanInfo.vasPlan : [];
  productCount += vasPlan.length;
  // check for ADSL
  const isADSL = isInternetAdded && techDetail === 'ADSL' && !isUndefinedNullOrEmpty(serviceTerm);
  const modemFee = isInternetAdded ? parseFloat(equipmentCost).toFixed(2) : 0;
  let simCharge = isMobileAdded ? Number(serviceTerm?.filter(val => val?.servLevel === wirelessPlanInfo[0]?.servLevel)[0]?.servShipping) : 0;
  const isWhiAdded = isInternetAdded && internetPlan?.servCode === 'ELFW';
  const isWhiWirelessAdded = isInternetAdded && internetPlan?.servCode === 'ELFW' && (internetPlan?.servLevel === 'ELFW_Unl300G' || internetPlan?.servRef === '522');

  const isVasAdded = vasPlan && vasPlan.length > 0;
  // bundle
  const isBundleAdded = selectedBundleData ? Object.keys(selectedBundleData)?.length > 0 : false;

  let adslCost = adslShippingCost, vasPrice = 0, adslPromoPrice = 0, oneTimeCharge = 0, monthlyCharge = 0, total = 0, promoPriceValid = false, selectedServX4YPromo = {}, servX4YAmount = '0', servX4YEnd = '', servX4YUnit = 'month';
  const selectedServiceTerm = serviceTerm?.length && serviceTerm?.filter(service => service.servServRef === internetPlan.servRef)[0];

  const spanishLanguagePref = languagePref;

  const getMobilePrice = (mobilePlan, page) => {
    let mobilePrice = getPromoPrice(mobilePlan, serviceTerm);
    if (!mobilePrice || (isBundleAdded && page === 'cart')) {
      mobilePrice = getProductPrice(mobilePlan, serviceTerm);
    }
    return getValidIntCost(mobilePrice);
  };

  let mobileMrcCombinedVal = 0;
  const servType = isBundleAdded ? 'Service' : 'mrcPos';

  const chargeMrcPosIpbb = mrcPosProducts?.includes('ipbb');

  const mrcPosIntCharge = Number(selectedServiceTerm?.servX4Y?.find(item => chargeMrcPosIpbb ? item?.servType?.toLowerCase() === 'mrcpos' : item?.servType?.toLowerCase() === 'install')?.servX4YAmount) || 0;



  if (isVasOnlyFlow) {
    if (vasOrdered) {
      if (vasPlanInfo && vasPlanInfo.vasPlan) {
        vasPlanInfo.vasPlan.forEach((vasProd) => {
          vasPrice += getValidIntCost(vasProd.price);
          servRef = vasProd.servRef
        })
      }
    }
    installationCost = 0;
    oneTimeCharge = 0;
    monthlyCharge = vasPrice;
  } else {
    price = getValidIntCost(getProductPrice(internetPlan, serviceTerm) ? getProductPrice(internetPlan, serviceTerm) : 0);
    equipmentCost = getValidIntCost(equipmentCost);
    installationCost = getValidIntCost(installationCost);
    oneTimeCharge = installationCost;
    monthlyCharge = (equipmentCost + price + vasPrice);

    if (isUndefinedNullOrEmpty(adslShippingCost)) {
      adslCost = 19.95;
    }

    // check for promo price
    if (isInternetAdded && !isUndefinedNullOrEmpty(serviceTerm)) {
      selectedServX4YPromo = serviceTerm && selectedServiceTerm?.servX4Y.find(item => item.servType.toLowerCase() === 'promo') || {};
      const installCost = Number(
        serviceTerm && selectedServiceTerm?.servX4Y?.find(item => item.servType.toLowerCase() === 'install')?.servX4YAmount || 0,
      );
      oneTimeCharge = mrcPosIntCharge > 0 ? mrcPosIntCharge : installCost;
      installationCost = mrcPosIntCharge > 0 ? mrcPosIntCharge : installCost;
    }

    if (Object.keys(selectedServX4YPromo).length) {
      servX4YAmount = selectedServX4YPromo.servX4YAmount;
      servX4YEnd = selectedServX4YPromo.servX4YEnd;
      servX4YUnit = selectedServX4YPromo.servX4YUnit;

      if (getValidIntCost(servX4YAmount) < price && !isUndefinedNullOrEmpty(servX4YAmount)) {
        price = getValidIntCost(servX4YAmount)
        promoPriceValid = true;
        monthlyCharge = equipmentCost + getValidIntCost(servX4YAmount) + vasPrice;
      }
    }

    if (isADSL || promoPriceValid) {
      adslPromoPrice = `*After ${servX4YEnd}  ${parseInt(servX4YEnd) > 1 ? `${servX4YUnit}s : ${getValid$Cost(internetPlan.price)}` : `: ${getValid$Cost(price)}`}`
      oneTimeCharge = adslCost;
    }

    // check for wireless plans
    if (isMobileAdded) {
      price = price + wirelessPlanInfo.reduce((accum, item) => parseFloat(accum) + parseFloat(item.quantity * getMobilePrice(item, 'cart')), 0)
      mobileMrcCombinedVal = wirelessPlanInfo?.reduce((acc, current) => {
        return acc + serviceTerm?.find(val => val?.servLevel === current?.servLevel)?.servX4Y?.find(el => el?.servType?.toLowerCase() === servType?.toLowerCase())?.servX4YAmount * current?.quantity
      }, 0);

      oneTimeCharge += mobileMrcCombinedVal > 0 ? mobileMrcCombinedVal + simCharge : simCharge ;
      monthlyCharge = (equipmentCost + price);
      servRef = !isUndefinedNullOrEmpty(servRef) ? `${servRef},${wirelesServeRef}` : wirelesServeRef;
    }

    // check for vas plans
    // Ire script/ cjPixel vars
    const ireItems = [];
    if (vasOrdered) {
      if (vasPlan.length > 0) {
        vasPlan.forEach((vasProd) => {
          let vasArr = {};
          vasPrice += getValidIntCost(vasProd.price);
          servRef += `${!isUndefinedNullOrEmpty(servRef) ? ',' : ''}${vasProd.servRef}`;
          vasArr = {
            subTotal: getValidIntCost(vasProd.price),
            category: vasProd.technology,
            sku: vasProd.servRef,
            quantity: 1,
            name: vasProd.techDetail,
          }
          ireItems.push(vasArr);
        })
        monthlyCharge += vasPrice;
      }
      if (isInternetAdded) {
        ireItems.push({
          subTotal: getValidIntCost(price),
          category: techDetail,
          sku: internetPlan.servRef,
          quantity: 1,
          name: internetPlan.serviceName,
        });
      }
    }
  }
  total = monthlyCharge + oneTimeCharge;

  const isMrcAndBundleDiscountApplied = isBundleAdded && mrcPosProducts?.includes(selectedBundleData?.category);

  const getOneTimeChargesHeadingValue = () => {
    if (isWhiAdded) {
      return getValid$Cost(setupCost);
    }
    return getValid$Cost(isMrcAndBundleDiscountApplied ? (Number(oneTimeCharge) - bundleDiscount)?.toFixed(2) : oneTimeCharge?.toFixed(2));
  }

  const translateLanguage = languagePref ?  TRANSLATE.spanish : TRANSLATE.english;

  const getOneTimeChargesLabel = () => {
    const pathname = '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    if (mrcPosIntCharge > 0 && chargeMrcPosIpbb && internetPlan?.servCode === 'ipbb') {
      return `${replaceServiceName(internetPlan?.serviceName)} - First Month`;
    } else if (isADSL) {
      if(pathname === '/thanks' ){
        return translateLanguage.shippingCost;
      }return 'Shipping Cost'
    } else if (isWhiAdded) {
      if(pathname === '/thanks'){
        return translateLanguage.processingAndHandlingFee;
      }return 'Processing & Handling Fee'
    }
    if(pathname === '/thanks'){
      return translateLanguage.installationCost;
    }return 'Installation Cost'
  }

  const getMobilePriceLabel = (mobilePlan) => {
    const pathname = '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
    return `(${mobilePlan?.quantity}) ${pathname === '/thanks' ? translateLanguage.mobile : 'Mobile'}  ${mobilePlan?.serviceName?.replace('Plan', '')?.replace('plan', '')}   ${pathname === '/thanks' ? ('- '+translateLanguage.firstMonth) : ' -  First Month'}   `;
  }

  const getOneTimeChargesLabelValue = () => {
    if (mrcPosIntCharge > 0 && chargeMrcPosIpbb && internetPlan?.servCode === 'ipbb') {
      return getValid$Cost(Number(mrcPosIntCharge)?.toFixed(2));
    } else if (isADSL) {
      return getValid$Cost(adslCost);
    } else if (isWhiAdded) {
      return getValid$Cost(setupCost);
    }
    return getValid$Cost(Number(installationCost)?.toFixed(2));
  }

  const getMobilePriceValue = (mobilePlan) => {
    return Number(serviceTerm?.find(el => el?.servLevel === mobilePlan?.servLevel)?.servX4Y?.find(el => el?.servType.toLowerCase() === servType?.toLowerCase())?.servX4YAmount*mobilePlan?.quantity) || 0
  }

  const InternetBundles = useMemo(() => {
    if (bundleData) {
      return bundleData?.filter(e => e.servCode1?.toLowerCase() === 'ipbb');
    }
  }, [bundleData]);

  const MobileBundles = useMemo(() => {
    if (bundleData) {
      return bundleData?.filter(e => e.servCode1?.toLowerCase() === 'mobile')
    }
  }, [bundleData])

  const mobileProductServRef = getWirelessServRef(wirelessPlanInfo);

  const allBundlesServRef = [];
  bundleData?.forEach(e => {
    if (e.productsServRef?.includes('ipbb*')) {
      plans?.forEach((ele) => {
        const getServRefArr = e.productsServRef?.toString();
        const replaceIpbbwithSevref = getServRefArr?.replace('ipbb*', ele.servRef) || '';
        allBundlesServRef.push({ code: e.bundleCode, servCodes: replaceIpbbwithSevref });
      });
    }
    if (e.productsServRef?.includes('mobile*')) {
      allBundlesServRef.push({ code: e.bundleCode, servCodes: `mobile${e?.productsServRef?.length}` });
    }
  });

  const updateSelectedBundle = () => {
    if ((isInternetAdded || isVasAdded || isMobileAdded) && isBundleVisible) {
      let bundles = [];
      allBundlesServRef?.forEach(element => {
        const bundleServref = element?.servCodes?.split(',');
        if (
          element?.servCodes === `mobile${wirelessPlanInfo.length}` ||
          element?.servCodes === `mobile${wirelesServeRef?.split(',')?.length}`
        ) {
          const ifExist = bundleData.find(e => e.bundleCode === element.code) || null;
          if (ifExist) {
            bundles.push(ifExist);
            return;
          }
        }
        let result = bundleServref?.every((el) => servRef?.split(',').includes(el)
          && bundleServref?.filter(val => val === el).length
          <=
          servRef?.split(',').filter(val => val === el).length
        );
        if (result) {
          const ifExist = bundleData.find(e => e.bundleCode === element.code) || null;
          bundles.push(ifExist);
        }
      });
      if (bundles?.length > 0) {
        const bundleWithMaxDiscount = bundles?.reduce((prev, current) =>
          Math.abs(Number(prev?.creditAmount)) > Math.abs(Number(current?.creditAmount)) ? prev : current,
        );
        dispatch(setSelectedBundle(bundleWithMaxDiscount));
      } else {
        dispatch(setSelectedBundle(null));
      }
    }
  }

  const bundleDiscount = isBundleAdded ? Math.abs(Number(bundleData?.find(el => el?.bundleCode === selectedBundleData?.bundleCode)?.creditAmount)) : 0;

  let sortedData = [];

  if (plans) {
    sortedData = plans.sort((a, b) => {
      return a.downstreamSpd - b.downstreamSpd;
    });
  }

  const productsWith12MonthFixWirelessServCode = sortedData.filter(
    item => item?.servCode === 'FixWireless' &&
      parseInt(item?.contractLength) === 12 &&
      (
        item.downstreamSpd / 1000 === 500 ||       // 500 Mbps
        item.downstreamSpd / 1000 === 2000 ||     // 2 GB
        item.downstreamSpd / 1000 === 250   // 250 Mbps
      )
  );

  return {
    isProductsAdded,
    productCount,
    internetPlan,
    wirelessCartInfo,
    vasPlanInfo,
    isInternetAdded,
    isMobileAdded,
    isADSL,
    adslCost,
    installationCost: (installationCost).toFixed(2),
    modemFee,
    simCharge,
    setupCost,
    isWhiAdded,
    oneTimeCharge: (oneTimeCharge).toFixed(2),
    monthlyCharge: (monthlyCharge).toFixed(2),
    total: (total).toFixed(2),
    adslPromoPrice,
    servRef,
    errorMessage,
    vasPlan,
    isMobileContainer,
    serviceTerm,
    spanishLanguagePref,
    selectedBundleData,
    bundleDiscount,
    isBundleAdded,
    isVasAdded,
    InternetBundles,
    MobileBundles,
    updateSelectedBundle,
    getOneTimeChargesHeadingValue,
    getOneTimeChargesLabel,
    getOneTimeChargesLabelValue,
    translateLanguage,
    mobileProvider,
    allBundlesServRef,
    isBundleVisible,
    getMobilePriceLabel,
    getMobilePriceValue,
    getMobilePrice,
    mobileMrcCombinedVal,
    mrcPosIntCharge,
    mobileProductServRef,
    chargeMrcPosIpbb,
    isMrcAndBundleDiscountApplied,
    generalTerms,
    isWhiWirelessAdded,
    selectedProduct,
    broadbandEmailError,
    sortedData,
    productsWith12MonthFixWirelessServCode
  };
}

export const useMbox = () => {
  const { isElnkMailboxReq, mboxRequired } = useSelector(
    (state) => ({
      isElnkMailboxReq: state.OrderState.isElnkMailboxReq,
      mboxRequired: state.OrderState.mboxRequired,
    }),
  );

  return {
    isElnkMailboxReq,
    mboxRequired
  };
}

export const useVendor = () => {
  const { internetPlan, extendedInfo } = useSelector(
    (state) => ({
      extendedInfo: state.OrderState.extendedInfo,
      internetPlan: state.OrderState.internetPlan,
    }),
  );

  const vendor = extendedInfo && extendedInfo ? extendedInfo.find((result) => result.productId === internetPlan?.servRef)?.vendor : null

  if (vendor?.toLowerCase()?.includes('verizon')) {
    return { vendor: 'Verizon' };
  }

  return {
    vendor: vendor ? vendor.toUpperCase() : ''
  };
};

export const useBusinessAddr = () => {
  const { isBusinessAddress, businessInfo } = useSelector(
    (state) => ({
      isBusinessAddress: state.OrderState.isBusinessAddress,
      businessInfo: state.OrderState.businessInfo
    }),
  );

  return {
    isBusinessAddress,
    businessInfo
  };
};

export const useAccountCheck = () => {
  const { accountCheck, checkingAccount } = useSelector(
    (state) => ({
      accountCheck: state.OrderState.accountCheck,
      checkingAccount: state.OrderState.checkingAccount,
    }),
  );

  return {
    checkAccount: accountCheck,
    checkingAccount
  };
};

export const useScheduleDate = () => {
  const { scheduleDateTime = '' } = useSelector((state) => ({
    scheduleDateTime: state.OrderState.scheduleDateTime,
  }));
  return {
    scheduleDateTime
  };
};

export const useStepsData = () => {
  const { paymentInfo, chgQuestionInfo } = useSelector((state) => ({
    paymentInfo: state.OrderState.paymentInfo,
    chgQuestionInfo: state.OrderState.chgQuestionInfo
  }));
  return {
    paymentInfo,
    chgQuestionInfo
  };
}

export const useLoading = () => {
  const { loading, scheduleLoading, expressLoading, hasError, TCPADisclaimer, broadbandEmailLoading } = useSelector(
    (state) => ({
      loading: state.OrderState.loading,
      scheduleLoading: state.OrderState.scheduleLoading,
      orderSubLoading: state.OrderState.orderSubLoading,
      expressLoading: state.OrderState.expressLoading,
      hasError: state.OrderState.hasError,
      TCPADisclaimer: state.OrderState.TCPADisclaimer,
      broadbandEmailLoading: state.OrderState.broadbandEmailLoading
    }),
  );

  return {
    loading,
    scheduleLoading,
    expressLoading,
    hasError,
    TCPADisclaimer,
    broadbandEmailLoading
  };
};

export const useOrderSubmissionData = () => {
  const { orderSubmitResponse } = useSelector(
    (state) => ({
      orderSubmitResponse: state.OrderState.orderSubmitResponse,
    }),
  );

  return {
    orderSubmitResponse
  };
};

export const useVasData = () => {
  const { vasError, expressVasProducts, isVasOnlyFlow } = useSelector(
    (state) => ({
      expressVasProducts: state.OrderState.expressVasProducts,
      vasError: state.OrderState.vasError,
      isVasOnlyFlow: state.OrderState.isVasOnlyFlow,
    }),
  );

  return {
    vasError,
    expressVasProducts,
    isVasOnlyFlow
  };
};

export const useBuyFlow = () => {
  const { buyflow } = useSelector(
    (state) => ({
      buyflow: state.OrderState.buyflow,
    }),
  );

  return {
    buyflow,
  };
};

export const useCallCenterFlag = () => {
  const buyflow = config.BUYFLOW_ENABLED === 'true'
    ? true
    : config.BUYFLOW_ENABLED === 'false'
      ? false
      : null;
  const [start = '', end = ''] = config.CALL_CENTER_TIME.split('-') ?? [];
  const checkTime = () => {
    if (typeof buyflow === 'boolean') {
      return !buyflow;
    }
    const date = new Date(); // current time
    const hours = date.getHours();
    return hours >= parseInt(start) && hours < parseInt(end) ? true : false;
  }
  const showPromoStickers = (config.SHOW_CALL_NOW === 'true' && !checkTime());
  const showCallNow = config.SHOW_CALL_NOW === 'true' && checkTime();
  return { showCallNow, showPromoStickers };
};
