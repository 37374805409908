import React, { useEffect, useState, useMemo } from 'react';
import { Row, Col } from 'antd';
import {
  getAggregatedAddress,
  getProductPrice,
  getPromoPrice,
  getValid$Cost,
  getWirelessServRef,
  vendorInformation,
} from '../../../common/common';
import { useDispatch } from 'react-redux';
import {
  getServicePropsByCode,
  saveInternetPlan,
  saveVasPlanInfo,
  saveWirelessPlanInfo,
  setMBoxReqObj,
  setSelectedBundle,
  setSelectedProduct,
} from '../../../store/action/OrderAction';
import {
  useBusinessAddr,
  useCallCenterFlag,
  useCartData,
  useMbox,
  useSessionData,
} from '../../../hooks/order';
import {
  EECproductAddToCart,
  EECproductRemoveFromCart,
} from '../../../common/GTMScripts';
import { v4 as uuid } from 'uuid';
import useLeadsSDK from '../../../hooks/useLeadsSDK';
import WhiModal from '../../common/WhiModal';
import PropTypes from 'prop-types';

import ReviewCarousel from './ReviewCarousel';
import SpeedCard from './speedCard';
import { EarthlinkPerks } from './EarthlinkPerks';
import BusinessSpeedInfoCard from './BusinessSpeedInfoCard';

const InternetPlans = (props) => {
  const leads = useLeadsSDK();
  const dispatch = useDispatch();
  const {
    serviceabilityCheck,
    productSpd300,
    productTfn,
    normalizedAddress,
    onResetAddress,
  } = props;

  const { plans, serviceTerm, fixedWirelessProducts } = serviceabilityCheck;
  const isWinterPromo = true;

  const [showWhiAddedModal, setShowWhiAddedModal] = useState(false);
  const [showFilteredPlans, setShowFilteredPlans] = useState(true);
  const [showWhiAndFixWirelessPlans, setShowWHIAndFixWirelessPlans] = useState(true);
  const {
    internetPlan = {},
    selectedBundleData,
    isBundleAdded,
    selectedProduct,
    sortedData,
    productsWith12MonthFixWirelessServCode
  } = useCartData();
  const { mboxRequired } = useMbox();
  const isInternetAdded = Object.keys(internetPlan).length > 0;
  const { showCallNow, showPromoStickers } = useCallCenterFlag();
  const { extendedInfo, customTFN } = useSessionData();
  const { isBusinessAddress } = useBusinessAddr();
  const handleAddIntToCart = (product) => {
    dispatch(getServicePropsByCode(product.servCode)).then((res) => {
      mboxRequired[product.servCode] =
        res.action?.payload?.data?.item?.isMboxReq;
      dispatch(setMBoxReqObj(mboxRequired));
    });
    dispatch(saveInternetPlan(product));
    EECproductAddToCart(product);
    leads?.updateOrder?.('add internet product to cart', {
      products: [
        {
          data: {
            category: 'internet',
            contractLength: product?.contractLength,
            downstreamSpd: product?.downstreamSpd,
            ebbInstallationCost: product?.ebbInstallationCost,
            ebbShippingCost: product?.ebbShippingCost,
            equipmentCost: product?.equipmentCost,
            upstreamSpd: product?.upstreamSpd,
          },
          id: String(product?.servRef),
          name: product?.serviceName,
          price: String(product?.price),
          quantity: 1,
        },
      ],
    });
  };

  const addIntToCart = (product) => {
    if (isInternetAdded) {
      if (isBundleAdded && selectedBundleData?.category !== 'mobile') {
        dispatch(
          saveVasPlanInfo({
            vasPlan: [],
            vasProductCart: [],
          }),
        );
        dispatch(
          saveWirelessPlanInfo({
            wirelessPlanInfo: [],
            wirelesServeRef: getWirelessServRef([]),
          }),
        );
        dispatch(setSelectedBundle(null));
      }

      delete mboxRequired[product.servCode];
      dispatch(setMBoxReqObj(mboxRequired));
      EECproductRemoveFromCart(product);
      dispatch(saveInternetPlan({}));

      /**
       * Remove internet product from cart
       */
      leads?.updateOrder?.('remove internet product from cart', {
        products: [
          {
            id: String(product?.servRef),
            name: product?.serviceName,
            quantity: 0,
          },
        ],
      });
    } else if (
      product.servCode?.toLowerCase() === 'elfw' &&
      !isBusinessAddress
    ) {
      setShowWhiAddedModal(true);
      dispatch(setSelectedProduct(product));
    } else {
      handleAddIntToCart(product);
    }
  };


  const combinedWhiAndFixWirelessProducts = showWhiAndFixWirelessPlans
    ? [...fixedWirelessProducts, ...productsWith12MonthFixWirelessServCode]
    : productsWith12MonthFixWirelessServCode; // variable fixedWiressProducts is actually a whi products with ServCode elfw

  const showDiscount = (product) => {
    const { downstreamSpd = 0 } = product;
    const vendor = vendorInformation(product, extendedInfo);
    if (vendor.toLowerCase() === 'frontier') {
      if (downstreamSpd / 1000 === 200) {
        return 'noDiscount';
      } else if (downstreamSpd / 1000 > 200) {
        return 'moreDiscount';
      } else {
        return '';
      }
    } else if (
      vendor.toLowerCase() === 'centurylink' &&
      downstreamSpd / 1000 >= 100
    ) {
      return 'noDiscount';
    } else if (downstreamSpd / 1000 === 100) {
      return 'Discount';
    } else if (downstreamSpd / 1000 > 100) {
      return 'Discount';
    } else {
      return '';
    }
  };
  const customTfn = () => {
    const hasPlanUnder100mb = plans?.some(
      (product) => Number(product.downstreamSpd) < 100000,
    );
    const planGreaterThan100 = plans?.some(
      (product) => Number(product.downstreamSpd) > 100000,
    );
    if (
      (productsWith12MonthFixWirelessServCode?.length > 0 && isBusinessAddress) ||
      (productsWith12MonthFixWirelessServCode?.length > 0 && fixedWirelessProducts?.length > 0)
    ) {
      return '833-906-5913';  // Fixed wireless plans With servCode fixWireless are available or Fixed Wireless plan With servCode fixWireless and Fixed Wireless plan With servCode ELFW both are available
    } else if (fixedWirelessProducts?.length > 0 && !(planGreaterThan100 || hasPlanUnder100mb)) {
      return '833-905-5435';  // Only Fixed Wireless plan With servCode ELFW available
    } else if (hasPlanUnder100mb) {
      return '833-905-7575';  // IPBB plan under 100mb should still return this
    } else if (fixedWirelessProducts?.length > 0 || planGreaterThan100) {
      return '833-905-5435';  // Fixed Wireless plan With servCode ELFW + IPBB OR Only IPBB (above 100mb)
    } else if (customTFN) {
      return customTFN;
    } else {
      return productTfn;
    }
  };
  const filteredPlans = useMemo(() => {
    return plans?.filter(
      (item) =>
        item.downstreamSpd === '300000' ||
        item.downstreamSpd === '1000000' ||
        item.downstreamSpd === '5000000',
    );
  }, [plans]);

  useEffect(() => {
    setShowFilteredPlans(filteredPlans?.length > 0);
  }, [filteredPlans]);

  const toggleData = () => {
    setShowFilteredPlans((prevState) => !prevState);
  };

  const showMostPopular = (i, product) => {
    const { downstreamSpd = 0 } = product;
    const productSpd = downstreamSpd / 1000 === 300;
    const vendor = vendorInformation(product, extendedInfo);

    if (vendor.toLowerCase() === 'at&t' && productSpd) {
      return 'mostPop';
    }
    if (
      i.toString() ===
      `${showCallNow || showPromoStickers ? displayedPlans.length - 1 : '0'
      }` &&
      !productSpd300
    ) {
      return 'mostPop';
    }
    return '';
  };

  const allplansClass = () => {
    if (showPromoStickers) {
      return 'promo-sticker';
    } else if (plans.length) {
      return 'internet-promo';
    }
  };
  const convertSpeedUnit = (speed) => {
    if (speed?.toLowerCase().includes('mb')) {
      return speed.replace(/mb/i, ' Mbps');
    } else if (speed?.toLowerCase().includes('gb')) {
      return speed.replace(/gb/i, ' Gigs');
    }
    return speed;
  };

  useEffect(() => {
    if (Object.keys(internetPlan).length) {
      const isMatch = filteredPlans.some(
        (item) => item.downstreamSpd === internetPlan.downstreamSpd,
      );

      setShowFilteredPlans(isMatch);
    }
  }, [internetPlan]);
  const pathname =
    '/' +
    window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
  const displayedPlans = showFilteredPlans ? filteredPlans : sortedData;
  let condition = showCallNow || showPromoStickers;

  return (
    <>
      <div className="internet-gift-card-section">
        {isWinterPromo ? (
          ''
        ) : fixedWirelessProducts.length === 0 && pathname === '/products' ? (
          <div>
            Use code <span>FIBER24 </span>to receive a $200 gift card**
          </div>
        ) : null}
      </div>

      {(combinedWhiAndFixWirelessProducts?.length > 0 && isBusinessAddress) ? (
        <>
          <div className="internet-business-result-heading internetOuterClass">
            <div className="fixed-wireless-main-section">
              <div className="business-plan-header-section">
                <h1>
                  Great news, partner – we&apos;re in your neighborhood!
                </h1>
                <p className="address-paragraph">
                  Elevate your network experience with Fixed Wireless designed for businesses like yours.

                </p>
                <p className="address-paragraph-with-btn">
                  Let&apos;s get you connected at{' '} <br />
                  <b>{getAggregatedAddress(normalizedAddress)}</b>
                  &nbsp;<button onClick={onResetAddress}>Change Address</button>
                </p>
              </div>

              <div className="fixed-wireless-list-section">
                <h2>With Fixed Wireless, you can expect: </h2>

                <ul>
                  <li>99.9% Uptime with Reliable Speeds </li>
                  <li>24/7 U.S.-Based Support & Monitoring </li>
                  <li>Unlimited Data on a Private Line</li>
                  <li>Dedicated Line-of-Sight Connection</li>
                  <li>No Throttling, Ever</li>
                  <li>Fast Installation in Two Weeks</li>
                </ul>
              </div>
            </div>

            {fixedWirelessProducts?.length > 0 ? (
              <div className="toggle-button-container">
                <button onClick={() => setShowWHIAndFixWirelessPlans((prevState) => !prevState)}>
                  Show all speeds &nbsp;
                  {showWhiAndFixWirelessPlans ? (
                    <i className="fa-solid fa-angle-up"></i>
                  ) : (
                    <i className="fa-solid fa-angle-down"></i>
                  )}
                </button>
              </div>
            ) : null}

            <div
              className={`all-single-plans internetOuterClass ${allplansClass()}`}
            >
              {/* All plans data */}
              <Row>

                {/* Rendering fixedWirelessProducts */}
                {combinedWhiAndFixWirelessProducts?.length > 0 &&
                  combinedWhiAndFixWirelessProducts.map((data) => {

                    let serviceDesc = '';

                    serviceDesc = Array.isArray(fixedWirelessProducts) && fixedWirelessProducts.includes(data)
                      ? data?.serviceDescription // For whiProducts
                      : data?.serviceDesc; // For productsWith12MonthFixWirelessServCode

                    const serviceDescList = serviceDesc
                      ?.split(/\. (?=\D)/) // Split only on ". " followed by a non-digit character
                      .filter((item) => item !== '')
                      .map((listItem) => {
                        return (
                          <li key={uuid()}>
                            <p>{listItem.trim() + '.'}</p>
                          </li>
                        );
                      });

                    return (
                      <Col
                        md={24}
                        lg={showCallNow ? 8 : 12}
                        key={uuid()}
                        className="plan-single"
                      >
                        <div className={'plan-single-inner wireless-prod'}>
                          <div className="business-plans-header">
                            <div className="business-plans-header-full-info">
                              <BusinessSpeedInfoCard speed={
                                data?.downstreamSpd &&
                                data?.downstreamSpd / 1000
                              } />
                              <h4>
                                {data?.shortName
                                  ?.replace(/Fixed Wireless\s?/i, '') // Remove "Fixed Wireless"
                                  ?.replace(/(\d+)mo/i, '$1month') // Replace "mo" with "month"
                                  ?.replace(/(\d+)(MB|GB)/i, '$1 $2') // Add space before MB and GB
                                  ?.split(/(\d+month)/i) // Split into parts at "36month"
                                  .map((part, index) => (
                                    /\d+mo/i.test(part) ? (
                                      <span key={index} className="small-text">{part}</span>
                                    ) : (
                                      <React.Fragment key={index}>{part}</React.Fragment>
                                    )
                                  ))}
                              </h4>
                            </div>

                            {data?.downstreamSpd && <div className="product-plan-speed">
                              <p>{data?.downstreamSpd / 1000} x {data?.downstreamSpd / 1000}</p>
                            </div>}
                          </div>
                          <ul>{serviceDescList}</ul>

                          {!showCallNow ? (
                            <div className="down-cart d-flex al-center j-between">
                              <button
                                className={`blue-button ${isInternetAdded && 'grey-button'
                                }`}
                                disabled={
                                  isInternetAdded &&
                                  internetPlan.servRef !== data.servRef
                                }
                                onClick={() => {
                                  addIntToCart(data);
                                  dispatch(setSelectedProduct(data));
                                }}
                              >
                                {isInternetAdded &&
                                  internetPlan.servRef === data.servRef
                                  ? 'Remove'
                                  : 'Add'}{' '}
                                <i
                                  className={`fa-solid ${isInternetAdded &&
                                    internetPlan.servRef === data.servRef
                                    ? 'fa-times'
                                    : 'fa-solid fa-cart-shopping'
                                  }`}
                                ></i>
                              </button>

                              <div className="main-below-total">
                                {getPromoPrice(data) && (
                                  <span className="discount">
                                    {getValid$Cost(data.price)}
                                  </span>
                                )}
                                <span className="total-single">
                                  {getPromoPrice(data)
                                    ? getPromoPrice(data)
                                    : getValid$Cost(data.price)}{' '}
                                  / mo
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="agent-call-button">
                              <a
                                href={`tel:${customTfn()}`}
                                className="blue-button elnk-tfn-link"
                              >
                                <i className="fa-solid fa-mobile-screen-button"></i>{' '}
                                <span className="phone-span elnk-tfn-tag">
                                  {customTfn()}
                                </span>
                              </a>
                            </div>
                          )}
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </div>
          </div>
          <EarthlinkPerks showCallNow={showCallNow} productsWith12MonthFixWirelessServCode={productsWith12MonthFixWirelessServCode} isBusinessAddress={isBusinessAddress} />
          <ReviewCarousel showCallNow={showCallNow} />
        </>
      ) :
        <div className="internet-result-heading internetOuterClass">
          <h1>
            {fixedWirelessProducts?.length > 0 && plans.length > 0
              ? 'Great News! High-speed internet is coming your way—you\'re in our network!'
              : fixedWirelessProducts?.length > 0 && plans.length === 0
                ? 'Checkout'
                : 'Great News! High-speed internet is coming your way—you\'re in our network!'
            }
          </h1>
          <p className="address-paragraph">
            Now it’s time to get you set up. Your address is <b>{getAggregatedAddress(normalizedAddress)}</b>
            &nbsp;<button onClick={onResetAddress}>Change Address</button>
          </p>
          {fixedWirelessProducts?.length === 0 ? (
            <div className="toggle-button-container">
              <button onClick={toggleData}>
                Show all speeds &nbsp;
                {showFilteredPlans ? (
                  <i className="fa-solid fa-angle-up"></i>
                ) : (
                  <i className="fa-solid fa-angle-down"></i>
                )}
              </button>
            </div>
          ) : null}

          <div
            className={`all-single-plans internetOuterClass ${allplansClass()}`}
          >
            {/* All plans data */}
            <Row>
              {plans?.length > 0 && fixedWirelessProducts?.length > 0
                ? plans?.map((product, i) => {
                  let serviceDesc = '';
                  serviceDesc = product?.serviceDesc
                    ?.split('.')
                    .filter((item) => item !== '')
                    .map((listItem) => {
                      return (
                        <li key={uuid()}>
                          <p>{listItem && listItem + '.'}</p>
                        </li>
                      );
                    });

                  return (
                    <Col
                      md={24}
                      key={uuid()}
                      lg={showCallNow ? 8 : 12}
                      className={`plan-single ${showMostPopular(i, product)} ${condition ? showDiscount(product) : ''
                      }`}
                    >
                      <div className="plan-single-inner">
                        <div className="plans-header">
                          <h4>
                            {product?.downstreamSpeed &&
                              convertSpeedUnit(product?.downstreamSpeed)}
                          </h4>
                          <SpeedCard
                            speed={
                              product?.downstreamSpd &&
                              product?.downstreamSpd / 1000
                            }
                          />
                        </div>
                        <ul>{serviceDesc}</ul>

                        {!showCallNow ? (
                          <div
                            className={`down-cart d-flex al-center j-between ${showPromoStickers ? '' : 'show-promo'
                            }`}
                          >
                            <button
                              className={`blue-button ${isInternetAdded ? 'grey-button' : ''
                              }`}
                              disabled={
                                isInternetAdded &&
                                internetPlan.servRef !== product.servRef
                              }
                              onClick={() => {
                                addIntToCart(product);
                              }}
                            >
                              {isInternetAdded &&
                                internetPlan.servRef === product.servRef
                                ? 'Remove'
                                : 'Add'}{' '}
                              <i
                                className={`fa-solid ${isInternetAdded &&
                                  internetPlan.servRef === product.servRef
                                  ? 'fa-times'
                                  : 'fa-solid fa-cart-shopping'
                                }`}
                              ></i>
                            </button>

                            <div className="main-below-total">
                              {getPromoPrice(product, serviceTerm) && (
                                <span className="discount">
                                  {getValid$Cost(product.price)}
                                </span>
                              )}
                              <span className="total-single">
                                {getPromoPrice(product, serviceTerm)
                                  ? getPromoPrice(product, serviceTerm)
                                  : getProductPrice(product, serviceTerm)}
                                / mo
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="agent-call-button">
                            <a
                              href={`tel:${customTfn()}`}
                              className="blue-button elnk-tfn-link"
                            >
                              <i className="fa-solid fa-phone-volume"></i>
                              <span className="phone-span elnk-tfn-tag">
                                {customTfn()}
                              </span>
                            </a>
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })
                : // Rendering filteredPlans
                displayedPlans?.map((product, i) => {
                  let serviceDesc = '';
                  serviceDesc = product?.serviceDesc
                    ?.split('.')
                    .filter((item) => item !== '')
                    .map((listItem) => {
                      return (
                        <li key={uuid()}>
                          <p>{listItem && listItem + '.'}</p>
                        </li>
                      );
                    });

                  return (
                    <Col
                      md={24}
                      key={uuid()}
                      lg={showCallNow ? 8 : 12}
                      className={`plan-single ${showMostPopular(i, product)} ${condition ? showDiscount(product) : ''
                      }`}
                    >
                      <div className="plan-single-inner">
                        <div className="plans-header">
                          <h4>
                            {product?.downstreamSpeed &&
                              convertSpeedUnit(product?.downstreamSpeed)}
                          </h4>
                          <SpeedCard
                            speed={
                              product.downstreamSpd &&
                              product.downstreamSpd / 1000
                            }
                          />
                        </div>
                        <ul>{serviceDesc}</ul>

                        {!showCallNow ? (
                          <div
                            className={`down-cart d-flex al-center j-between ${showPromoStickers ? '' : 'show-promo'
                            }`}
                          >
                            <button
                              className={`blue-button ${isInternetAdded ? 'grey-button' : ''
                              }`}
                              disabled={
                                isInternetAdded &&
                                internetPlan.servRef !== product.servRef
                              }
                              onClick={() => {
                                addIntToCart(product);
                              }}
                            >
                              {isInternetAdded &&
                                internetPlan.servRef === product.servRef
                                ? 'Remove'
                                : 'Add'}{' '}
                              <i
                                className={`fa-solid ${isInternetAdded &&
                                  internetPlan.servRef === product.servRef
                                  ? 'fa-times'
                                  : 'fa-solid fa-cart-shopping'
                                }`}
                              ></i>
                            </button>

                            <div className="main-below-total">
                              {getPromoPrice(product, serviceTerm) && (
                                <span className="discount">
                                  {getValid$Cost(product.price)}
                                </span>
                              )}
                              <span className="total-single">
                                {getPromoPrice(product, serviceTerm)
                                  ? getPromoPrice(product, serviceTerm)
                                  : getProductPrice(product, serviceTerm)}
                                / mo
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="agent-call-button">
                            <a
                              href={`tel:${customTfn()}`}
                              className="blue-button elnk-tfn-link"
                            >
                              <i className="fa-solid fa-phone-volume"></i>
                              <span className="phone-span elnk-tfn-tag">
                                {customTfn()}
                              </span>
                            </a>
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })}

              {/* Rendering fixedWirelessProducts */}
              {fixedWirelessProducts &&
                fixedWirelessProducts.length > 0 &&
                fixedWirelessProducts.map((data) => {
                  let serviceDesc = '';
                  serviceDesc = data?.serviceDescription
                    ?.split('.')
                    .filter((item) => item !== '')
                    .map((listItem) => {
                      return (
                        <li key={uuid()}>
                          <p>{listItem && listItem + '.'}</p>
                        </li>
                      );
                    });

                  return (
                    <Col
                      md={24}
                      lg={showCallNow ? 8 : 12}
                      key={uuid()}
                      className="plan-single"
                    >
                      <div className={'plan-single-inner wireless-prod'}>
                        <div className="plans-header">
                          <h4>
                            {data.serviceName
                              .replace('plan', '')
                              .replace('Plan', '')}{' '}
                            Data
                          </h4>
                        </div>
                        <ul>{serviceDesc}</ul>

                        {!showCallNow ? (
                          <div className="down-cart d-flex al-center j-between">
                            <button
                              className={`blue-button ${isInternetAdded && 'grey-button'
                              }`}
                              disabled={
                                isInternetAdded &&
                                internetPlan.servRef !== data.servRef
                              }
                              onClick={() => {
                                addIntToCart(data);
                                dispatch(setSelectedProduct(data));
                              }}
                            >
                              {isInternetAdded &&
                                internetPlan.servRef === data.servRef
                                ? 'Remove'
                                : 'Add'}{' '}
                              <i
                                className={`fa-solid ${isInternetAdded &&
                                  internetPlan.servRef === data.servRef
                                  ? 'fa-times'
                                  : 'fa-solid fa-cart-shopping'
                                }`}
                              ></i>
                            </button>

                            <div className="main-below-total">
                              {getPromoPrice(data) && (
                                <span className="discount">
                                  {getValid$Cost(data.price)}
                                </span>
                              )}
                              <span className="total-single">
                                {getPromoPrice(data)
                                  ? getPromoPrice(data)
                                  : getValid$Cost(data.price)}{' '}
                                / mo
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className="agent-call-button">
                            <a
                              href={`tel:${customTfn()}`}
                              className="blue-button elnk-tfn-link"
                            >
                              <i className="fa-solid fa-mobile-screen-button"></i>{' '}
                              <span className="phone-span elnk-tfn-tag">
                                {customTfn()}
                              </span>
                            </a>
                          </div>
                        )}
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </div>
      }

      {fixedWirelessProducts?.length === 0 ? (
        <>
          <EarthlinkPerks showCallNow={showCallNow} />
          <ReviewCarousel showCallNow={showCallNow} />
        </>
      ) : null}

      <WhiModal
        showWhiAddedModal={showWhiAddedModal}
        setShowWhiAddedModal={setShowWhiAddedModal}
        selectedProduct={selectedProduct}
        handleAddIntToCart={handleAddIntToCart}
      />
    </>
  );
};

InternetPlans.propTypes = {
  serviceabilityCheck: PropTypes.object.isRequired,
  productSpd300: PropTypes.bool.isRequired,
  productTfn: PropTypes.string.isRequired,
  normalizedAddress: PropTypes.object.isRequired,
  onResetAddress: PropTypes.func.isRequired,
};

export default InternetPlans;
