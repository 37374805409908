import React, { useEffect, useState } from 'react';
import AppRoutes from './routes/Routes';
import { getConfigs, setConfig } from './config';

const { LEADS_SDK_SCRIPT_SRC } = getConfigs();

function App() {
  const config = getConfigs();
  const [,setElnkConfigUpdated] = useState(0);

  const setElnkConfig = (key, value) => {
    setConfig(key, value);
    setElnkConfigUpdated(Date.now());
  }

  // Initialize from client-side config settings
  useEffect(() => {
    Object
      .keys(window?._elnkCheckout?.config ?? {})
      .forEach((key) => {
        setConfig(key, window._elnkCheckout.config[key]);
      });
    setElnkConfigUpdated(Date.now());
  }, []);

  // Set initial client-side config settings
  useEffect(() => {
    if (!window?._elnkCheckout) {
      window._elnkCheckout = {
        config: {
          ...(window?._elnkCheckout?.config ?? {}),
        },
        setElnkConfig,
      };
    }
  }, []);

  // Redirect to headless checkout if partner request
  useEffect(() => {
    if (window.location.hash === '' && window.location.pathname.startsWith('/p/')) {
      const [,uri] = window.location.href.split('/p/');

      window.location.href = `${config.HEADLESS_CHECKOUT_BASE_URL}/#/partner/${uri}`;
    }
  }, []);

  /**
   * script tag for leads api sdk
   */
  useEffect(() => {
    if (LEADS_SDK_SCRIPT_SRC) {
      const script = document.createElement('script');
      script.src = LEADS_SDK_SCRIPT_SRC;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <AppRoutes />
  );
}

export default App;
