import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatTFN, getBaseUrl, getTFN } from '../../common/common';
import { EECCheckout, pageLoad } from '../../common/GTMScripts';
import {
  useBusinessAddr,
  useCallCenterFlag,
  useCartData,
  useLoading,
  useServiceablitityData,
  useSessionData,
} from '../../hooks/order';
import { cartLogo, earthlinkLogo } from '../../images/index';
import { routesConfig } from '../../util/util';
import FixedCardFooter from './FixedCardFooter';
import { saveSpanishLanguagePreference } from '../../store/action/OrderAction';
import { useDispatch } from 'react-redux';
import { Switch } from 'antd';

const HeaderMain = () => {
  const dispatch = useDispatch();
  const { productCount, spanishLanguagePref } = useCartData();
  const { sessionResponse, TFN, customTFN } = useSessionData();
  const { isInternetAdded, internetPlan, wirelessCartInfo, vasPlan, productsWith12MonthFixWirelessServCode } =
    useCartData();
  const [showCart, setShowCart] = useState(true);
  const [tfn, setTfn] = useState(TFN ? formatTFN(TFN) : getTFN());
  const { loading, scheduleLoading } = useLoading();
  const pathname =
    '/' +
    window?.location?.href.substring(window.location.href.lastIndexOf('/') + 1);
  const showFooter =
    pathname?.includes(routesConfig?.products) ||
    pathname?.includes(routesConfig?.vasPage) ||
    pathname?.includes(routesConfig?.checkout) ||
    pathname?.includes('servRef');
  const childRef = React.createRef();
  const { showCallNow, showPromoStickers } = useCallCenterFlag();
  const { serviceabilityCheck } = useServiceablitityData();
  const { isBusinessAddress } = useBusinessAddr();
  const { fixedWirelessProducts = [], plans = [] } = serviceabilityCheck;
  const isWinterPromo = true;

  useEffect(() => {
    if (loading) {
      document.body.classList.add('body-loading');
    } else {
      document.body.classList.remove('body-loading');
    }
  }, [loading]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const HLPlan = isInternetAdded ? [internetPlan] : [];
    const allProducts = [
      ...HLPlan,
      ...wirelessCartInfo.wirelessPlanInfo,
      ...vasPlan,
    ];
    if (sessionResponse && !window.location.href.includes('addr')) {
      pageLoad(sessionResponse.uid);
    }
    if (productCount > 0) {
      EECCheckout(allProducts);
    }
    if (
      window?.location?.href.includes(routesConfig?.thanks) ||
      window?.location?.href.includes(routesConfig?.reviewOrder)
    ) {
      setShowCart(false);
    } else {
      setShowCart(true);
    }
    if (window.location.href.includes(routesConfig.products)) {
      document.body.classList.add('product-page');
    } else {
      document.body.classList.remove('product-page');
    }
  }, [sessionResponse, window.location.href]);

  useEffect(() => {
    if (TFN) {
      setTfn(formatTFN(TFN));
    }
  }, [TFN, window.location.href]);

  const customheadertfn = () => {
    const hasPlanUnder100mb = plans?.some(
      (product) => Number(product.downstreamSpd) < 100000,
    );
    const planGreaterThan100 = plans?.some(
      (product) => Number(product.downstreamSpd) > 100000,
    );

    if (
      (productsWith12MonthFixWirelessServCode?.length > 0 && isBusinessAddress) ||
      (productsWith12MonthFixWirelessServCode?.length > 0 && fixedWirelessProducts?.length > 0)
    ) {
      return '833-906-5913';  // Fixed wireless plans With servCode fixWireless are available or Fixed Wireless plan With servCode fixWireless and Fixed Wireless plan With servCode ELFW both are available
    } else if (fixedWirelessProducts?.length > 0 && !(planGreaterThan100 || hasPlanUnder100mb)) {
      return '833-905-5435';  // Only Fixed Wireless plan With servCode ELFW available
    } else if (hasPlanUnder100mb) {
      return '833-905-7575';  // IPBB plan under 100mb should still return this
    } else if (fixedWirelessProducts?.length > 0 || planGreaterThan100) {
      return '833-905-5435';  // Fixed Wireless plan With servCode ELFW + IPBB OR Only IPBB (above 100mb)
    } else if (customTFN) {
      return customTFN;
    } else {
      return tfn;
    }
  };

  const handleCartClick = () => {
    childRef.current.cartClick();
    document.body.classList.add('cart-clicked');
  };

  const onChange = useCallback(() => {
    dispatch(saveSpanishLanguagePreference(!spanishLanguagePref));
  }, [spanishLanguagePref]);
  const filteredPlans = useMemo(() => {
    return plans?.filter(
      (item) =>
        item.downstreamSpd === '300000' ||
        item.downstreamSpd === '1000000' ||
        item.downstreamSpd === '5000000',
    );
  }, [plans]);
  return (
    <>
      {showFooter &&
        !scheduleLoading &&
        Object.keys(sessionResponse).length > 0 && (
        <div>
          <FixedCardFooter ref={childRef} />
        </div>
      )}

      <div
        className={`outer-header ${
          showCallNow || showPromoStickers ? 'agent_online_header' : ''
        }`}
      >
        <header>
          <div className="elink-container">
            <div className="d-flex j-between al-center">
              <div className="logo">
                <a href={getBaseUrl()}>
                  <img src={earthlinkLogo} alt="Earthlink Logo" />
                </a>
              </div>
              {pathname === '/thanks' ? (
                <div className="toggle-btn">
                  <div className="btn-with-toggle">
                    <Switch
                      onChange={onChange}
                      checkedChildren="Spanish"
                      unCheckedChildren="English"
                      checked={spanishLanguagePref}
                    />
                  </div>
                </div>
              ) : null}

              <div className="gift-card-section">
                {isWinterPromo ? (
                  ''
                ) : filteredPlans?.length > 0 && pathname === '/products' ? (
                  <div>
                    Use code <span>FIBER24</span> to receive a $200 gift card**
                  </div>
                ) : null}
              </div>

              <div className="d-flex al-center">
                <div className="header-tfn d-flex al-center">
                  <a
                    className="elnk-tfn-link"
                    href={`tel:${customheadertfn()}`}
                    data-ctc="header"
                  >
                    <i className="fa-solid fa-mobile-screen-button"></i>
                    <span className="phone-span elnk-tfn-tag">
                      {customheadertfn()}
                    </span>
                  </a>
                </div>
                {/* {showCart && <div className="header-cart d-flex al-center" onClick={()=>{handleCartClick();}}> */}
                {showCart && (
                  <button
                    className="header-cart button-cart d-flex al-center"
                    onClick={() => {
                      handleCartClick();
                    }}
                    onKeyDown={() => {}}
                  >
                    <img src={cartLogo} alt="cart-logo" />
                    <span>{productCount}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default HeaderMain;
